@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Jost&family=Libre+Franklin&family=Spectral&family=Open+Sans&family=Radley&display=swap');
$wine: #3f0b21;
$gold: #dbad52;
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    background: #fff;
    color: #111;
}

ul {
    list-style: none;
    display: flex;
    gap: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', sans-serif;
}

p,
button,
span,
input,
label,
a,
li {
    font-family: 'Radley', sans-serif;
}

p {
    font-size: 16px;
}

img {
    width: 100%;
    max-height: 500px;
    height: auto;
}

a {
    text-decoration: none;
    color: inherit;
}

input,
textarea {
    background: none;
    border: none;
    outline: none;
}

button {
    background: none;
    border: none;
    text-transform: capitalize;
    padding: .6rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
}

section {
    width: 90%;
    margin: 0 auto;
}

nav {
    z-index: 100;
    .active {
        color: $gold;
    }
    header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
    }
    ul {
        gap: 1rem;
        a {
            text-transform: capitalize;
            font-size: 1rem;
            color: #777;
        }
    }
}

.logo {
    display: flex;
    align-items: center;
    z-index: 100;
    height: 60px;
    width: 200px;
    margin-bottom: -1rem;
    margin-left: -1rem;
    img {
        height: auto;
    }
}

.mobile {
    display: none;
}

.main-home {
    background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(../assets/homemain.webp);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    height: 90vh;
    padding: 2rem 0;
}

.home {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    height: 80vh;
    padding: 2rem 0;
    h2 {
        font-size: 2.5rem;
        text-transform: capitalize;
        font-weight: 700;
        max-width: 900px;
    }
    h6 {
        font-size: 1rem;
        margin: 1rem 0;
        font-weight: 500;
        text-transform: capitalize;
    }
    p {
        color: $gold;
        width: 80%;
        margin: 0 auto;
        line-height: 1.5rem;
        text-transform: capitalize;
    }
    button {
        margin-top: 1.5rem;
    }
    .home-text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 5px;
        max-width: 700px;
        margin: 10px auto;
        font-family: 'Playfair Display', sans-serif;
        justify-content: center;
        span {
            text-transform: capitalize;
            height: 100px;
            font-family: 'Playfair Display', sans-serif;
            color: $gold;
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: 2rem 0;
    img {
        object-fit: contain;
        border-radius: 4px;
    }
}

.headline {
    font-size: 2rem;
    text-transform: capitalize;
    margin: 0.8rem 0;
    font-weight: 700;
}

.tag {
    background: $gold;
    color: #fff;
    text-transform: uppercase;
}

.fourgrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin: 2rem 0;
}

.center {
    display: flex;
    text-align: center;
    flex-direction: column;
    p {
        margin: 0 auto;
        max-width: 700px;
        font-weight: 500;
    }
}

.header {
    p {
        font-weight: 500;
    }
}

.gold {
    background: $gold;
    padding: 2rem 0;
    color: #fff;
}

.black {
    background: #111;
    padding: 2rem 0;
    color: #fff;
}

.white {
    background-color: #fff;
    color: #111;
    padding: 12px 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(14, 31, 53, 0.12);
    font-size: 14px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.8;
        transition: all 0.3s ease;
    }
}

.button-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.button {
    background: $gold;
    color: #fff;
    padding: 12px 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(14, 31, 53, 0.12);
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.8;
        transition: all 0.3s ease;
    }
}

.btn {
    border: 1px solid $wine;
    color: $wine;
    margin-top: 1rem;
    border-radius: 5px;
}

.aboutus {
    margin: 4rem 0;
    .grid {
        place-items: center;
    }
}

.offer {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    text-align: center;
    .offers-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        margin: 2rem 0;
        .box {
            width: 250px;
            transition: all 0.4s ease;
            img {
                height: 165px;
                object-fit: cover;
                border-radius: 5px;
            }
            h6 {
                font-size: 1.15rem;
                text-transform: uppercase;
                margin: 8px 0;
            }
            p {
                font-size: 1rem;
                width: 90%;
                margin: 0 auto;
            }
        }
    }
}

.news {
    padding: 2rem 0;
    .headline {
        text-align: center;
    }
    .news-cont {
        width: 90%;
        height: 100%;
        margin: 3rem auto;
    }
    img {
        height: 275px;
    }
}

.review-card {
    max-width: 700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 auto;
    .text {
        display: flex;
        flex-direction: column;
        text-align: left;
        h4,
        h6 {
            text-transform: capitalize;
        }
        .pro {
            margin-top: 16px;
        }
    }
}

.swiper {
    max-width: 1000px;
    min-height: 260px;
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
    }
    .swiper-pagination-bullet-active {
        background: $gold;
    }
}

.teamswiper {
    height: 400px;
    .swiperslide {
        width: auto;
    }
    .swiper-pagination {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.engine {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    max-width: 800px;
    background: rgba(243, 192, 26, 0.3);
    padding: 10px 0 1rem;
    border-radius: 10px;
    margin: 2rem auto;
    img {
        width: 200px;
        height: 100px;
        object-fit: contain;
        margin-top: 1rem;
    }
    p {
        text-transform: capitalize;
    }
    h4 {
        text-transform: capitalize;
        font-size: 1.5rem;
    }
}

footer {
    color: #fff;
    background: #0e0b01;
    .footer {
        padding: 4rem 0 0;
        .footer-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 45px;
            max-width: 1240px;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 auto;
            // display: grid;
            // grid-template-columns: repeat(5, 1fr);
        }
        .footer-desc {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;
            width: 223px;
            .social {
                ul {
                    display: flex;
                    gap: 1rem;
                    flex-direction: row;
                }
                a {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    color: $gold;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    border: 1px solid $gold;
                    &:hover {
                        transition: all 0.2s ease;
                        transform: scale(0.95);
                        color: $wine;
                        background: $gold;
                    }
                }
            }
        }
        .foot {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 110px;
            ul {
                flex-direction: column;
                gap: 0.4rem;
                text-transform: capitalize;
            }
            h6 {
                text-transform: uppercase;
                margin: 10px 0;
                font-size: 1rem;
                font-weight: 700;
            }
            span {
                text-transform: lowercase;
            }
        }
        .foot-input {
            width: 320px;
        }
        .form-input {
            width: 100%;
            input {
                width: 90%;
                background: #fff;
                border-radius: 5px;
                padding: 1rem;
                margin-bottom: 1rem;
            }
            button {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                text-align: center;
            }
        }
        .foot-mail {
            width: 195px;
            li {
                display: flex;
                gap: 10px;
                align-items: center;
                a {
                    text-transform: lowercase;
                    color: $gold;
                    margin-bottom: 10px;
                    text-decoration: underline;
                }
            }
        }
    }
}

.copy {
    width: 100%;
    color: #111;
    background: $gold;
    text-transform: capitalize;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 3rem;
    padding: 1rem;
    font-weight: 700;
}

///scrollup
.fixed {
    position: fixed;
}

.scrollup {
    width: 2.75rem;
    height: 2.75rem;
    right: 1rem;
    bottom: 1.5rem;
    background-color: $gold;
    color: #fff;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
    z-index: 100;
    &:hover {
        transform: scale(1.1);
    }
    &:active {
        transform: scale(0.8);
    }
}

/////about//////////////////////
/// ../assets/aboutbg.webp
.about-home {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("https://source.unsplash.com/faEfWCdOKIg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    // height: 90vh;
    padding: 2rem 0;
}

.about-page {
    h2 {
        font-size: 2rem;
    }
    h5 {
        font-size: 1.4rem;
        text-transform: capitalize;
    }
    .who {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        padding: 2rem 0;
        max-width: 700px;
        margin: 1rem auto;
    }
    .about-story {
        margin: 2rem 0;
        .grid {
            place-items: center;
        }
        p {
            font-weight: 400;
            line-height: 20px;
        }
    }
    .about-vision {
        padding: 3rem 0;
        background: $gold;
        color: #fff;
        display: flex;
        justify-content: center;
        text-align: center;
        .grid {
            padding: 2rem 0;
            margin-top: 2rem;
        }
        .item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            p {
                width: 85%;
                margin: 0 auto;
            }
        }
        svg {
            font-size: 3rem;
        }
    }
    .about-grid {
        padding: 2rem 0;
        width: 100%;
        .grid {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 60px;
            width: 100%;
        }
        .text {
            width: 600px;
        }
        img {
            width: 500px;
            height: 400px;
        }
        .header {
            margin-bottom: 2rem;
            h4,
            p {
                text-align: center;
            }
            p {
                font-weight: 500;
            }
        }
    }
}

.team {
    margin: 2rem 0;
    .headline {
        text-align: center;
    }
    .team-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin: 2rem auto;
    }
    .item {
        background: #fff;
        border-radius: 5px;
        width: 320px;
        height: 320px;
        padding: 10px;
        box-shadow: 0px 4px 8px rgba(14, 31, 53, 0.12);
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            transform: scale(0.95);
            transition: all 0.3s ease;
        }
        .text {
            padding: 5px;
        }
        h4 {
            font-size: 1.25rem;
            text-transform: capitalize;
        }
        h6 {
            font-weight: normal;
            font-size: 14px;
            text-transform: capitalize;
        }
        img {
            height: 250px;
            border-radius: 10px;
            object-fit: contain;
        }
        .space-between {
            align-items: center;
            flex-direction: row;
            padding: 10px 0;
        }
        svg {
            cursor: pointer;
            color: $gold;
            display: flex;
            align-items: center;
        }
    }
}

/////Service//////////////////////
.service-home {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('https://images.pexels.com/photos/3869651/pexels-photo-3869651.jpeg?auto=compress&cs=tinysrgb&w=600');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    // height: 90vh;
    padding: 2rem 0;
}

.what-we-offer-section {
    margin: 3rem 0;
}

.what-we-offer {
    article {
        display: flex;
        gap: 10px;
        align-items: center;
        margin: 1rem 0;
    }
    div {
        display: flex;
        align-items: center;
    }
    svg {
        color: $wine;
    }
}

.service-page {
    img {
        object-fit: cover;
        max-height: 350px;
    }
    .service-container {
        padding: 2rem 0;
    }
    .article {
        margin-top: 3rem;
        p {
            line-height: 1.7rem;
        }
    }
}

/////Contact//////////////////////
/// 
.contact-home {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../assets/contactbg.webp);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    // height: 90vh;
    padding: 2rem 0;
}

.contact-page {
    background-color: #111;
    color: #fff;
    .grid {
        place-items: center;
    }
    a {
        color: $gold;
        text-decoration: underline;
    }
    p {
        margin: 10px 0;
    }
    .form-container {
        color: #fff;
        padding: 1rem;
        border-radius: 10px;
        .headline {
            margin: 0;
        }
        .btn {
            background: $wine;
            color: $gold
        }
        .form-input {
            margin: 1rem 0;
            input,
            textarea {
                width: 90%;
                border: 1px solid #111;
                background: #fff;
                padding: 1rem;
                margin-top: 5px;
                &::placeholder {
                    color: #111;
                    text-transform: capitalize;
                }
            }
        }
    }
}

/////blog//////////////////////
.blogs {
    .search {
        margin: 1rem auto;
    }
}

.blog-home {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../assets/blogbg.webp);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    // height: 90vh;
    padding: 2rem 0;
}

.article-container {
    place-items: center;
}

.blog-card {
    width: 340px;
    height: 440px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(14, 31, 53, 0.12);
    transition: all 0.3s ease;
    cursor: pointer;
    text-transform: capitalize;
    margin: 1rem 0;
    &:hover {
        transform: scale(0.9);
    }
    .text {
        height: 100%;
        padding: 10px;
    }
    h6,
    h5 {
        color: #808080;
    }
    h4 {
        margin: 8px 0;
        height: 35px;
    }
    img {
        border-radius: 10px 10px 0 0;
        width: 100%;
        height: 50%;
    }
    p {
        height: 60px;
        font-size: 0.9rem;
    }
    a {
        color: $gold;
        font-weight: 700;
    }
}

.flex {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.button-container {
    justify-content: center;
    flex-wrap: wrap;
    margin: 3rem 0;
    .round {
        border-radius: 25px;
        border: 1px solid #111;
        display: flex;
        align-items: center;
    }
    .active {
        border: none;
        background: $gold;
        color: #fff;
        font-weight: 800;
    }
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 700px;
    form {
        width: 100%;
    }
    p {
        margin-bottom: 5px;
    }
    .form-input {
        border: 1px solid #111;
        display: flex;
        justify-content: space-between;
    }
    svg {
        padding-left: 5px;
    }
    .button {
        border-radius: 0;
    }
}

.space-between {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
}

.courses-home {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../assets/coursesbg.webp);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    // height: 90vh;
    padding: 2rem 0;
}

.courses-cont {
    padding: 2rem 0;
    width: 100%;
    button {
        margin: 2rem auto;
    }
    .wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 29px;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
}

.margin {
    margin-bottom: 3rem;
}

.course-card {
    width: 300px;
    height: 300px;
    box-shadow: 0px 4px 8px rgba(14, 31, 53, 0.12);
    border-radius: 8px;
    padding: 0px 0px 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(0.9);
        transition: all 0.3s ease;
    }
    .image {
        height: 200px;
        img {
            height: 100%;
        }
    }
    .text {
        height: 100%;
        padding: 1rem 10px 0;
        h4 {
            height: 50px;
            font-size: 1.2rem;
            text-transform: capitalize;
        }
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 1rem;
        svg {
            font-size: 1rem;
        }
        p {
            font-size: 1rem;
        }
    }
}

.service-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    .modal-section {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    button {
        margin: 0;
    }
    .svg {
        display: flex;
        justify-content: right;
        margin: 0;
        svg {
            font-size: 1.25rem
        }
    }
    .modal-container {
        position: relative;
        width: 470px;
        height: auto;
        margin: 0 auto;
        background: #111111;
        color: #fff;
        box-shadow: 0px 4px 8px rgba(14, 31, 53, 0.12);
        color: #111;
        border-radius: 8px;
        padding: 2rem;
        @media screen and (max-width: 500px) {
            width: 330px;
        }
    }
    form {
        margin-top: .5rem;
        .form-input {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 1.5rem;
            label {
                color: #fff;
            }
            input {
                border-radius: 4px;
                width: 92%;
                padding: 1rem;
                background: #fff;
            }
        }
    }
    .text {
        text-align: center;
    }
    h4 {
        color: #fff;
        margin-bottom: 8px;
        font-size: 30px;
        @media screen and (max-width: 500px) {
            font-size: 27px;
        }
    }
    p {
        color: #fff;
    }
    .btn-div {
        width: 100%;
        margin-top: 2.5rem;
        button {
            margin: 0 auto;
        }
        @media screen and (max-width: 500px) {
            margin-top: 2rem;
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    .modal-section {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal-container {
        position: relative;
        width: 490px;
        min-height: 400px;
        margin: 0 auto;
        background: #111111;
        color: #fff;
        box-shadow: 0px 4px 8px rgba(14, 31, 53, 0.12);
        color: #111;
        border-radius: 8px;
        padding: 2rem;
        @media screen and (max-width: 500px) {
            width: 330px;
        }
    }
    form {
        margin-top: 2.5rem;
        .form-input {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 1.5rem;
            label {
                color: #fff;
            }
            input,
            select {
                border-radius: 4px;
                width: 92%;
                padding: 1rem;
                background: #fff;
                outline: none;
            }
            select {
                width: 100%;
            }
        }
    }
    .text {
        text-align: center;
    }
    h4 {
        color: #fff;
        margin-bottom: 8px;
        font-size: 30px;
        @media screen and (max-width: 500px) {
            font-size: 27px;
        }
    }
    p {
        color: #fff;
    }
    .btn-div {
        width: 100%;
        margin-top: 2.5rem;
        button {
            margin: 0 auto;
        }
        @media screen and (max-width: 500px) {
            margin-top: 2rem;
        }
    }
}

option {
    text-transform: capitalize;
    padding: 1rem;
}

.single-page {
    margin: 20vh 0;
    .image {
        width: 100%;
        max-width: 700px;
        margin: 1rem auto;
        img {
            height: 100%;
            object-fit: contain;
        }
    }
    h6 {
        text-transform: capitalize;
    }
    .author {
        font-size: 1rem;
        margin: 10px 0;
    }
    .grey {
        color: #808088;
    }
    article {
        h4 {
            text-transform: uppercase;
            font-size: 2.75rem;
            @media screen and (max-width: 500px) {
                font-size: 2rem;
            }
        }
        p {
            text-transform: capitalize;
            line-height: 28px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .teamswiper {
        .swiper-pagination {
            display: flex;
        }
    }
}

@media screen and (max-width: 900px) {
    section {
        width: 90%;
    }
    p {
        font-size: 16px;
    }
    nav {
        .menulist {
            display: none;
        }
        .show {
            background-color: #fff;
            flex-direction: column;
            gap: 1rem;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100vw;
            display: flex;
            transform: translateY(0%);
            z-index: 10;
            touch-action: none;
            transition: all 0.3s ease;
            .menu-btn {
                position: relative;
                left: 80%;
                top: 0;
            }
            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 5rem 1rem 0;
                gap: 2rem;
                height: 100%;
                a {
                    margin-bottom: 1rem;
                    font-size: 1.35rem;
                }
            }
        }
        .button {
            text-align: center;
            margin: 1rem auto;
        }
    }
    .none {
        display: none;
    }
    .mobile {
        display: flex;
        z-index: 100;
        svg {
            font-size: 1.3rem;
            color: #111;
        }
    }
    .home {
        height: 450px;
        h2 {
            font-size: 28px;
            width: 90%;
        }
        h6 {
            font-size: 0.8rem;
        }
    }
    .fourgrid {
        grid-template-columns: repeat(2, 1fr);
    }
    .grid {
        grid-template-columns: 100%;
    }
    .review-card {
        grid-template-columns: 100%;
    }
    .faq {
        .question {
            width: 100%;
        }
    }
    footer {
        .footer {
            .footer-container {
                gap: 35px;
            }
        }
    }
    .about-page {
        .about-grid {
            .grid {
                flex-direction: column;
            }
        }
    }
    .headline {
        font-size: 1.75rem;
    }
    .teamswiper {
        height: 400px;
    }
    .mySwiper {
        height: 500px;
    }
    .order-2 {
        order: 2;
    }
    .order-1 {
        order: 1;
    }
    .service-page {
        .service-container {
            .headline {
                text-align: center;
            }
        }
    }
}

.img-nav {
    width: 70px;
    height: 70px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    img {
        border-radius: 50%;
    }
}

@media screen and (max-width: 600px) {
    .about-page {
        .about-grid {
            .text {
                width: 100%;
            }
            img {
                width: 343px;
                height: 320px;
                margin: 0 auto;
            }
        }
    }
    footer {
        .footer {
            .footer-container {
                flex-direction: column;
            }
        }
    }
    .engine {
        padding: 10px 10px 1rem;
        h4 {
            font-size: 1.5rem;
        }
    }
}

@media screen and (max-width: 500px) {
    section {
        width: 95%;
    }
    nav {
        header {
            padding: 10px 0;
        }
    }
    .home {
        width: 100%;
        background-position: center;
        h2 {
            font-size: 1.5rem;
            width: 100%;
        }
        h6 {
            font-size: 0.85rem;
        }
        p {
            width: 100%;
            line-height: 1rem;
        }
        .flex {
            font-size: 1.2rem;
        }
    }
    .swiper {
        min-height: 350px;
    }
    .teamswiper {
        height: 400px;
    }
    .fourgrid {
        grid-template-columns: 100%;
    }
    .grid {
        margin: 1rem 0;
    }
    .headline {
        font-size: 1.4rem;
    }
    .blog-card {
        width: 300px;
        height: 410px;
    }
    .news {
        .news-cont {
            width: 100%;
        }
    }
    .about-page {
        .about-story {
            p {
                line-height: 1rem;
            }
        }
        .about-vision {
            .item {
                p {
                    width: 100%;
                }
            }
        }
    }
    .offer {
        .offers-cont {
            grid-template-columns: 100%;
            gap: 2rem;
            .box {
                margin: 1rem 0;
                img {
                    width: 100%;
                }
                p {
                    width: 100%;
                }
            }
        }
    }
    .service-page {
        .courses-cont {
            .wrap {
                gap: 1.5rem;
            }
            .item {
                width: 295px;
            }
        }
    }
    .faq {
        .header {
            h6 {
                font-size: 1rem;
            }
            svg {
                font-size: 1rem;
            }
        }
        p {
            margin-top: 1rem;
            font-size: 0.8rem;
        }
        .question {
            width: 100%;
        }
    }
    .service-page {
        .article {
            p {
                line-height: 1rem;
            }
        }
    }
    footer {
        .footer {
            .footer-container {
                gap: 30px;
            }
        }
    }
    .team {
        .item {
            h4 {
                font-size: 1.15rem;
            }
        }
    }
    .copy {
        font-size: 0.7rem;
    }
    .courses-cont {
        .item {
            width: 300px;
        }
    }
    .space-between {
        flex-direction: column;
        gap: 1.5rem;
    }
}